<template>
    <div>

        <b-button variant="secondary" @click="$router.back()">Voltar</b-button>

        <b-modal :title="'Boleto - Histórico -' + $route.params.name" id="history">

          <BoletoHistory :boletos="historySelected"></BoletoHistory>
        </b-modal>

        <Header :title="'Boletos - ' + $route.params.name" :hasAddBtn="false" />

        <b-table sticky-header="65vh" no-border-collapse hover :items="boletos" :fields="fields">
            <template #cell(actions)="row">
                <div class="btn-icons">
                    <div><i @click="get(row.item)" class = "material-icons btn-icon">download</i><span>Baixar</span></div>
                    <div v-if="row.item.history.length > 0"><i @click="history(row.item)" class = "material-icons btn-icon">history</i><span>Histórico</span></div>
                    <div v-if="canGenerate(row.item)"><i @click="generate(row.item)" class = "material-icons btn-icon">post_add</i><span>Gerar</span></div>
                </div>
            </template>
        </b-table>

        <b-modal :title="'Gerar Novo Boleto'" id="gerar" @ok="gerarBoletoOk">
          <template v-slot:modal-ok>Gerar Boleto</template>
          <template v-slot:modal-cancel>Cancelar</template>

          <p class="text-center">Selecione uma data a partir de hoje até 14 dias</p>
          <div class="d-flex justify-content-center">
            <b-calendar v-model="date" :date-disabled-fn="dateDisabled" locale="pt-BR"></b-calendar>
          </div>

        </b-modal>
    </div>
</template>

<style lang="stylus">

#history___BV_modal_footer_
  display none !important

#history___BV_modal_title_
  text-overflow ellipsis !important
  white-space nowrap !important
  overflow hidden !important

</style>

<script>

import Header from '@/components/Dashboard/Header'

import api from '@/services/condinvest'
import { toast } from '@/utils'

import BoletoHistory from '@/components/Modals/BoletoHistory'

function formatDate (date) {
  const v = date.split(' ')?.[0].split('-')
  return v[2] + '/' + v[1] + '/' + v[0]
}

function formatStatus (value, key, item) {
  const due = new Date(item.due_date)
  const pay = item.pay_date ? new Date(item.pay_date) : 0
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  const status =
    +item.protest ? 'Protestado' : (
      now > due ? 'Vencido em ' + formatDate(item.due_date) : (
        now <= due && !pay ? 'Em Aberto até ' + formatDate(item.due_date)
          : 'Pago em ' + formatDate(item.pay_date)))

  return status
}

export default {
  components: {
    Header, BoletoHistory
  },
  created () {
    // key

    api.get('object/index?type=contas&user_id=' + this.$route.params.id).then((response) => {
      console.log('BOLETOS', response.data)
      if (response.status === 200) {
        this.boletos = response.data.contas
      }
    }, error => {
      toast(this, 'danger', 'Erro ao obter boletos', error.message)
      return Promise.reject(error)
    })
  },
  data: () => ({
    boletos: [],
    historySelected: [],
    generateSelected: null,
    date: (new Date()).toISOString().split('T')[0],

    fields: [
      { key: 'key', label: 'Código', sortable: true },
      { key: 'unidade', label: 'Unidade', sortable: true },
      { key: 'value', label: 'Valor', sortable: true },
      // { key: 'pay_date', label: 'Vencimento', formatter: formatDate, sortable: true },
      { key: 'due_date', label: 'Status', formatter: formatStatus, sortable: true },
      // { key: 'protest', label: 'Protestado', formatter: (v) => v ? 'Sim' : 'Não', sortable: true },
      { key: 'code', label: 'Boleto' },
      { key: 'actions', label: 'Ações' }
    ]

  }),
  computed: {
    rules () {
      return this.$store.state.rules.map(a => a.key)
    }
  },
  methods: {
    get (item) {
      api.get('/object/get_boleto?user_id=' + this.$route.params.id + '&key=' + item.key).then((response) => {
        console.log('BOLETOS', response.data)
        if (response.status === 200) {
          console.log(response.data)
          window.open(response.data.uri, '_blank')
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter boleto', error.message)
        return Promise.reject(error)
      })
    },
    gerarBoletoOk () {
      // console.log('/object/get_boleto?user_id=' + this.$route.params.id + '&key=' + this.generateSelected.key + '&date_vality=' + this.date + '&type=new_bill')
      api.get('/object/get_boleto?user_id=' + this.$route.params.id + '&key=' + this.generateSelected.key + '&date_vality=' + this.date + '&type=new_bill').then((response) => {
        console.log('BOLETOS', response.data)
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data)
          window.open(response.data.uri, '_blank')
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter boleto', error.message)
        return Promise.reject(error)
      })
    },
    canGenerate (item) {
      const due = new Date(item.due_date)

      const nowRange = new Date() // com acrésimo
      nowRange.setHours(0, 0, 0, 0)
      nowRange.setDate(nowRange.getDate() - (item.nosso_numero.includes('2972804') ? 41 : 22))

      return !(+item.protest) && nowRange > due && !item.pay_date && !(+item.blocked) && item.code
    },
    generate (item) {
      this.generateSelected = item
      this.$bvModal.show('gerar')
    },
    history (item) {
      this.historySelected = item.history
      console.log(item)
      this.$bvModal.show('history')
    },
    dateDisabled (ymd, date) {
      const today14 = (new Date())
      today14.setHours(0, 0, 0, 0)
      today14.setDate(today14.getDate() + 14)
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return !(date >= today && date <= today14)
    }
  }
}
</script>
