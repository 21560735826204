<template>
    <b-table sticky-header="65vh" no-border-collapse hover :items="boletos" :fields="fields">
        <template #cell(actions)="row">
            <div class="btn-icons">
                <i @click="get(row.item)" class = "material-icons btn-icon">download</i>
            </div>
        </template>
    </b-table>
</template>

<script>

import api from '@/services/condinvest'
import { toast } from '@/utils'

function formatDate (date) {
  const v = date.split(' ')?.[0].split('-')
  return v[2] + '/' + v[1] + '/' + v[0]
}

export default {
  props: {
    boletos: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    fields: [
      { key: 'key', label: 'Código', sortable: true },
      { key: 'description', label: 'Vencido em', formatter: formatDate },
      { key: 'actions', label: 'Ações' }
    ]
  }),
  methods: {
    get (item) {
      api.get('/object/get_boleto?type=history&user_id=' + this.$route.params.id + '&key=' + item.key).then((response) => {
        console.log('BOLETOS', response.data)
        if (response.status === 200) {
          console.log(response.data)
          window.open(response.data.uri, '_blank')
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter boleto', error.message)
        return Promise.reject(error)
      })
    }
  }
}
</script>
